import { Button } from 'components/Button';
import { AutocompleteInput, Input } from 'components/Form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaDollarSign, FaPlus } from 'react-icons/fa6';
import { HiTrash } from 'react-icons/hi';

const ContractedLaneRow = ({ name_prefix, item, index, remove }) => {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            <AutocompleteInput
                id={`${name_prefix}.${index}.origin`}
                value={item.origin || ''}
                setValue={setValue}
                label='Origin'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Origin'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <AutocompleteInput
                id={`${name_prefix}.${index}.destination`}
                value={item.destination || ''}
                setValue={setValue}
                label='Destination'
                register={register}
                errors={errors}
                watch={watch}
                placeholder='Destination'
                size='sm'
                formStyling
                hideErrorMessage
                hideEndIcon
            />

            <Input
                type='text'
                size='sm'
                label={`${name_prefix}.${index}.rate`}
                placeholder='Total Rate'
                register={register}
                errors={errors}
                watch={watch}
                startIcon={<FaDollarSign />}
                validate={(value) => !isNaN(Number(value))}
            />

            <Button
                uitype='icon'
                radius='round'
                color='secondary-dark'
                onClick={(event) => {
                    event.preventDefault();
                    remove(index);
                }}
            >
                <HiTrash title='Delete Lane' />
            </Button>
        </div>
    );
};

const CustomRateContractedLanes = ({ name_prefix }) => {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: name_prefix,
    });

    return (
        <div>
            <p
                style={{
                    fontWeight: '600',
                    borderBottom: '1px solid black',
                    color: 'var(--secondary-black)',
                    width: 'fit-content',
                    margin: '0 0 4px 0',
                }}
            >
                Contracted lanes
            </p>

            <div
                style={{
                    padding: '8px 8px 0 0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                {fields.map((item, index) => (
                    <ContractedLaneRow
                        key={item.id}
                        name_prefix={name_prefix}
                        item={item}
                        index={index}
                        remove={remove}
                    />
                ))}
            </div>

            <Button
                uitype='ghost'
                color='primary-dark'
                size='xs'
                onClick={(event) => {
                    event.preventDefault();
                    append({});
                }}
            >
                <FaPlus /> Add lane
            </Button>
        </div>
    );
};

export { CustomRateContractedLanes };
