import { Button } from 'components/Button';
import { AutocompleteInput, Input } from 'components/Form';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

import styles from 'styles/Settings.module.css';
import { RateProviderItem } from './RateProviderItem';
import {
    CUSTOM_RATES_DISTANCES,
    CUSTOM_RATES_VEHICLES,
    CustomRateGrid,
} from './CustomRateGrid';
import { CustomRateContractedLanes } from './CustomRateContractedLanes';

function flattenObject(obj, parentKey = '', result = {}) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            const value = obj[key];

            if (
                typeof value === 'object' &&
                !Array.isArray(value) &&
                value !== null
            ) {
                flattenObject(value, newKey, result);
            } else {
                result[newKey] = value;
            }
        }
    }
    return result;
}

const providers = [
    {
        name: 'avrl',
        display_name: 'AVRL',
        extraFields: (form_methods) => {
            return (
                <Input
                    type='text'
                    placeholder='AVRL Shipper Code'
                    label='rate_providers.avrl.shipper_code'
                    register={form_methods.register}
                    watch={form_methods.watch}
                    errors={form_methods.errors}
                    size='sm'
                    narrow
                    required
                    hideErrorMessage
                />
            );
        },
        inline: true,
    },
    {
        name: 'custom',
        display_name: 'Custom',
        extraFields: (_form_methods) => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <CustomRateGrid name_prefix='rate_providers.custom.base_rates' />
                <CustomRateContractedLanes name_prefix='rate_providers.custom.contracted_lanes' />
            </div>
        ),
        inline: false,
    },
];

const AutoQuoteSettingsCard = ({ allowlistItem, onSubmit }) => {
    const defaultValues = useMemo(() => {
        const { default_pickup_location, rate_providers } =
            allowlistItem?.auto_quote_settings ?? {};

        const rate_providers_settings = rate_providers?.reduce(
            (acc, provider) => {
                const { rate_provider, ...settings } = provider;
                acc[`rate_providers.${rate_provider}.selected`] = true;
                const flattened_settings = flattenObject(settings);
                Object.entries(flattened_settings).forEach(
                    ([setting_name, setting_value]) => {
                        if (setting_name.includes('base_rates')) {
                            acc[
                                `rate_providers.${rate_provider}.${setting_name}`
                            ] = setting_value.reduce(
                                (vehicle_rates, base_rate) => {
                                    vehicle_rates[base_rate.id] =
                                        base_rate.rate;
                                    return vehicle_rates;
                                },
                                []
                            );
                        } else {
                            acc[
                                `rate_providers.${rate_provider}.${setting_name}`
                            ] = setting_value;
                        }
                    }
                );
                return acc;
            },
            {}
        );

        return {
            ...(default_pickup_location ? { default_pickup_location } : {}),
            ...(rate_providers_settings &&
            Object.keys(rate_providers_settings).length
                ? { ...rate_providers_settings }
                : {}),
        };
    }, [allowlistItem]);

    const form_methods = useForm();

    const {
        register,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = form_methods;

    useEffect(() => {
        Object.entries(defaultValues).forEach(([key, value]) => {
            setValue(key, value);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    const initial_order = useMemo(() => {
        let next_not_found_idx =
            allowlistItem?.auto_quote_settings?.rate_providers?.length ?? 0;

        return providers.reduce((acc, { name }) => {
            const provider_index =
                allowlistItem?.auto_quote_settings?.rate_providers?.findIndex(
                    ({ rate_provider }) => rate_provider === name
                );

            const index =
                provider_index > -1 ? provider_index : next_not_found_idx++;
            acc[name] = index;
            return acc;
        }, {});
    }, [allowlistItem?.auto_quote_settings?.rate_providers]);

    const [providersOrder, setProvidersOrder] = useState(initial_order);

    const submitHandler = useCallback(
        (values) => {
            const selected_providers = Object.entries(
                values?.rate_providers ?? {}
            )
                .filter(([_, settings]) => settings.selected)
                .map(([provider, settings]) => {
                    delete settings.selected;

                    if (provider === 'custom') {
                        const base_rates = CUSTOM_RATES_VEHICLES.reduce(
                            (acc_vehicle, { vehicle_class }) => {
                                const vehicle_rates =
                                    CUSTOM_RATES_DISTANCES.reduce(
                                        (acc_distance, distance) => {
                                            if (
                                                settings.base_rates[
                                                    vehicle_class
                                                ]?.[distance.id]
                                            ) {
                                                acc_distance.push({
                                                    ...distance,
                                                    rate: Number(
                                                        settings.base_rates[
                                                            vehicle_class
                                                        ][distance.id]
                                                    ),
                                                });
                                            }
                                            return acc_distance;
                                        },
                                        []
                                    );

                                if (vehicle_rates.length) {
                                    acc_vehicle[vehicle_class] = vehicle_rates;
                                }
                                return acc_vehicle;
                            },
                            {}
                        );

                        const contracted_lanes =
                            settings?.contracted_lanes.filter(
                                (lane) =>
                                    lane.origin && lane.destination && lane.rate
                            );

                        settings.base_rates = base_rates;
                        settings.contracted_lanes = contracted_lanes;
                    }

                    settings.margin = Number(settings.margin);

                    return {
                        rate_provider: provider,
                        ...settings,
                    };
                })
                .sort(
                    (a, b) =>
                        providersOrder[a.rate_provider] -
                        providersOrder[b.rate_provider]
                );
            onSubmit({
                ...values,
                rate_providers: selected_providers.length
                    ? selected_providers
                    : undefined,
            });
        },
        [onSubmit, providersOrder]
    );

    const changeProviderOrder = useCallback(
        (index, direction) => {
            setProvidersOrder((prev) => {
                const a = Object.entries(prev).find(
                    ([_, order]) => order === index
                )[0];
                const b = Object.entries(prev).find(
                    ([_, order]) => order === index + direction
                )[0];

                const tmp = { ...prev };

                tmp[a] = index + direction;
                tmp[b] = index;

                return tmp;
            });
        },
        [setProvidersOrder]
    );

    return (
        <div>
            <h2 className={styles.heading}>Auto Quote Rate Setting</h2>
            <FormProvider {...form_methods}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(submitHandler)}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <AutocompleteInput
                            id='default_pickup_location'
                            value={watch('default_pickup_location') || ''}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                            watch={watch}
                            placeholder='Default Pickup Location'
                            size='sm'
                            hideErrorMessage
                            hideEndIcon
                        />

                        <HiOutlineInformationCircle
                            size={24}
                            data-tooltip-content='A fallback when the customer does not specify a location in the email'
                            data-tooltip-id='default-pickup-info-tooltip'
                        />

                        <Tooltip id='default-pickup-info-tooltip' />
                    </div>

                    {providers
                        .sort(
                            (a, b) =>
                                providersOrder[a.name] - providersOrder[b.name]
                        )
                        .map(({ name, extraFields, inline }, index) => (
                            <div
                                key={name}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {index > 0 && (
                                        <FaArrowUp
                                            color='var(--everest-green)'
                                            onClick={() =>
                                                changeProviderOrder(index, -1)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                marginTop:
                                                    index ===
                                                    providers.length - 1
                                                        ? '0.25rem'
                                                        : 0,
                                            }}
                                        />
                                    )}
                                    {index < providers.length - 1 && (
                                        <FaArrowDown
                                            color='var(--everest-red)'
                                            onClick={() =>
                                                changeProviderOrder(index, 1)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                marginTop:
                                                    index === 0 ? '0.25rem' : 0,
                                            }}
                                        />
                                    )}
                                </div>

                                <div
                                    style={{
                                        padding: '0.25rem 0 0.25rem 0.5rem',
                                    }}
                                >
                                    {index + 1}.
                                </div>

                                <RateProviderItem
                                    name={name}
                                    checked={watch(
                                        `rate_providers.${name}.selected`
                                    )}
                                    onChange={() =>
                                        setValue(
                                            `rate_providers.${name}.selected`,
                                            !getValues(
                                                `rate_providers.${name}.selected`
                                            )
                                        )
                                    }
                                    extraFields={extraFields}
                                    inline={inline}
                                />
                            </div>
                        ))}

                    <Button
                        uitype='cta'
                        color='primary-dark'
                        size='lg'
                        fullwidth
                    >
                        Save Rates
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};

export { AutoQuoteSettingsCard };
