import { Input } from 'components/Form';
import { CheckboxInput } from 'components/Form/Input/CheckboxInput';
import { useFormContext } from 'react-hook-form';

const RateProviderItem = ({ name, checked, onChange, extraFields, inline }) => {
    const {
        register,
        watch,
        formState: { errors },
    } = useFormContext();

    const extra_fields = extraFields?.({ register, watch, errors });

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <CheckboxInput
                    name={name}
                    value={name}
                    label={`Use ${name} Rate`}
                    checked={checked}
                    onChange={onChange}
                />

                {checked && (
                    <>
                        <Input
                            type='text'
                            placeholder='Margin (%)'
                            label={`rate_providers.${name}.margin`}
                            register={register}
                            watch={watch}
                            errors={errors}
                            hideErrorMessage
                            size='sm'
                            narrow
                            validate={(value) => !isNaN(Number(value))}
                        />
                        {inline && extra_fields}
                    </>
                )}
            </div>
            <div>{checked && !inline && extra_fields}</div>
        </div>
    );
};

export { RateProviderItem };
