import useSWR from 'swr';

import { useUser } from 'components/Accounts';
import fetcher from 'lib/fetcher';

const useGetQuoteExtractionInboxes = () => {
    const { user, token } = useUser();

    const SWR_CONFIG = user
        ? [
              `${process.env.REACT_APP_SMARTBID_SERVER_BASE_URL}/api/quotes/inboxes/${user.company_id}`,
              { 'x-access-token': token },
          ]
        : null;

    const { data, error } = useSWR(SWR_CONFIG, fetcher, {
        onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
            // never retry on 404.
            if (error.status === 404) return;
            // only retry up to 3 times.
            if (retryCount >= 3) return;
            // retry after 1.5 seconds.
            setTimeout(() => revalidate({ retryCount }), 1500);
        },
    });

    const quote_extraction_inboxes = data && Array.isArray(data) ? data : [];
    const has_quote_extraction_inbox = quote_extraction_inboxes.length > 0;

    return {
        quote_extraction_inboxes,
        has_quote_extraction_inbox,
        error: error || data?.message, // check for message when API throws an error
    };
};

export { useGetQuoteExtractionInboxes };
