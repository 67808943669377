import { useGetAllowList } from 'hooks/useGetAllowList';
import { useForm } from 'react-hook-form';
import { Input } from 'components/Form';
import styles from './styles.module.css';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import {
    FaCheck,
    FaPencil,
    FaPlus,
    FaRegSquare,
    FaSquareCheck,
    FaTrashCan,
    FaX,
} from 'react-icons/fa6';
import { useUser } from 'components/Accounts';
import toast from 'react-hot-toast';
import { smartbidServer } from 'lib/api';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Modal } from 'components/Modal';
import { AutoQuoteSettingsCard } from './AutoQuoteSettingsCard';

/**
 * @function AllowlistItem
 * @description
 * A single item in the allowlist.
 * @param {
 *  item: {
 *     source_recipient: string,
 *     source_name: string,
 *     company_id: string,
 * }} param.item
 * @returns
 */
const AllowlistItem = ({ allowlistItem, showAutoQuote }) => {
    const { user } = useUser();
    const { mutate, key } = useGetAllowList();
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [priority, setPriority] = useState(Boolean(allowlistItem?.priority));
    const [autoQuote, setAutoQuote] = useState(
        Boolean(allowlistItem?.auto_quote)
    );

    const {
        register,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: allowlistItem,
    });

    const updateAutoQuote = useCallback(
        async (data) => {
            if (!allowlistItem) return;

            toast.loading('Updating Auto-Quote item', {
                id: 'toast-auto-quote-item',
            });

            try {
                await smartbidServer.patch(`/api/automail/vertex/allowlist`, {
                    company_id: allowlistItem.company_id,
                    source_recipient: allowlistItem.source_recipient,
                    source_name: allowlistItem.source_name,
                    new_source_recipient: allowlistItem.source_recipient,
                    priority: priority,
                    auto_quote: allowlistItem.auto_quote ?? false,
                    auto_quote_settings:
                        allowlistItem.auto_quote_settings ?? {},
                    ...data,
                });
                toast.success('Auto-quote for item updated', {
                    id: 'toast-auto-quote-item',
                });
                mutate(key);
            } catch (err) {
                console.log(err);
                toast.error('Error updating auto-quote for item', {
                    id: 'toast-auto-quote-item',
                });
            }
        },
        [allowlistItem, priority]
    );

    const onSubmit = async (data) => {
        // Check if fields are empty
        if (!data.source_name || !data.source_recipient) {
            setError('source_name', {
                message: 'Required',
            });
            setError('source_recipient', {
                message: 'Required',
            });
            return;
        }
        // Check if source_recipient is a valid email OR domain.
        // Essentially just check if it has a .<something> at the end or @.
        const regex =
            /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
        if (!regex.test(data.source_recipient)) {
            setError('source_recipient', {
                message: 'Invalid email or domain',
            });
            return;
        }
        try {
            if (!allowlistItem) {
                const companyId = user ? user.company_id : '';
                if (!companyId) {
                    throw new Error('Company ID not found');
                }
                await smartbidServer.post(`/api/automail/vertex/allowlist`, {
                    // Provide a fallback in case companyId is not provided
                    company_id: companyId,
                    source_recipient: data.source_recipient,
                    source_name: data.source_name,
                    priority: priority,
                });
                toast.success('Allowlist item added');
                reset({
                    source_name: '',
                    source_recipient: '',
                });
                setPriority(false);
            } else {
                await smartbidServer.patch(`/api/automail/vertex/allowlist`, {
                    company_id: allowlistItem.company_id,
                    source_recipient: allowlistItem.source_recipient,
                    source_name: data.source_name,
                    new_source_recipient: data.source_recipient,
                    priority: priority,
                    auto_quote: allowlistItem.auto_quote ?? false,
                    auto_quote_settings:
                        allowlistItem.auto_quote_settings ?? {},
                });
                toast.success('Allowlist item updated');
                setEditing(false);
            }

            mutate(key);
        } catch (e) {
            console.error(e);
            if (e instanceof AxiosError) {
                setError('source_name', {
                    type: 'manual',
                    message: e.response.data.message,
                });
            } else {
                setError('source_name', {
                    type: 'manual',
                    message: 'Error updating allowlist item',
                });
            }
            toast.error('Error updating allowlist item');
        }
    };

    const deleteItem = async () => {
        try {
            if (!allowlistItem) {
                throw new Error('No allowlist item to delete');
            }
            await smartbidServer.delete(`/api/automail/vertex/allowlist`, {
                data: {
                    company_id: allowlistItem.company_id,
                    source_recipient: allowlistItem.source_recipient,
                },
            });
            toast.success('Allowlist item deleted');
            mutate(key);
        } catch (error) {
            console.error(error);
            toast.error('Error deleting allowlist item');
        }
    };

    const [showCustomRatesModal, setShowCustomRatesModal] = useState(false);

    if (!editing && !deleting && allowlistItem) {
        return (
            <>
                <div
                    className={styles.whitelistitem}
                    id={allowlistItem.source_recipient}
                >
                    <div className={styles.item}>
                        <label>Name</label>
                        <span>{allowlistItem.source_name}</span>
                    </div>
                    <div className={styles.item}>
                        <label>Recipient</label>
                        <span>{allowlistItem.source_recipient}</span>
                    </div>
                    <div className={styles.item} style={{ maxWidth: '80px' }}>
                        <label>Priority</label>
                        <span>{allowlistItem.priority ? 'Yes' : 'No'}</span>
                    </div>
                    {showAutoQuote && (
                        <div
                            className={styles.item}
                            style={{ maxWidth: '100px' }}
                        >
                            <label>Auto-Quote</label>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className={classNames(
                                        styles.checkboxInput,
                                        autoQuote && styles.selected
                                    )}
                                    style={{
                                        justifyContent: 'flex-start',
                                        margin: 0,
                                    }}
                                    onClick={async () => {
                                        await updateAutoQuote({
                                            auto_quote: !autoQuote,
                                        });
                                        setAutoQuote((prev) => !prev);
                                    }}
                                >
                                    {autoQuote ? (
                                        <FaSquareCheck />
                                    ) : (
                                        <FaRegSquare />
                                    )}
                                </div>
                                <Button
                                    uitype='icon'
                                    color='primary-dark'
                                    size='sm'
                                    type='button'
                                    onClick={() =>
                                        setShowCustomRatesModal(true)
                                    }
                                >
                                    <FaPencil size={12} />
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className={styles.item}>
                        <label>Created</label>
                        <span>
                            {allowlistItem.created_at
                                ? new Date(
                                      allowlistItem.created_at
                                  ).toLocaleTimeString([], {
                                      year: 'numeric',
                                      month: 'numeric',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : '—'}
                        </span>
                    </div>
                    <div className={styles.item}>
                        <label>Updated</label>
                        <span>
                            {allowlistItem.updated_at
                                ? new Date(
                                      allowlistItem.updated_at
                                  ).toLocaleTimeString([], {
                                      year: 'numeric',
                                      month: 'numeric',
                                      day: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : '—'}
                        </span>
                    </div>
                    <Button
                        uitype='ghost'
                        color='primary-dark'
                        size='sm'
                        type='button'
                        onClick={() => setEditing(true)}
                    >
                        <FaPencil />
                    </Button>
                </div>

                {showCustomRatesModal && (
                    <Modal
                        wide
                        open={showCustomRatesModal}
                        handler={setShowCustomRatesModal}
                    >
                        <AutoQuoteSettingsCard
                            allowlistItem={allowlistItem}
                            onSubmit={(data) => {
                                updateAutoQuote({ auto_quote_settings: data });
                                setShowCustomRatesModal(false);
                            }}
                        />
                    </Modal>
                )}
            </>
        );
    }

    return (
        <div className={classNames(styles.form, styles.whitelistitem)}>
            <Input
                label='source_name'
                placeholder='Name'
                watch={watch}
                register={register}
                name='source_name'
                errors={errors}
                size='sm'
                className={styles.input}
                disabled={editing || !allowlistItem ? false : true}
            />
            <Input
                // Label is actually the name of the field
                // on the form object. This is a bit confusing
                // because traditionally, label is the text
                // that appears above the input and name is the
                // name of the field.
                label='source_recipient'
                placeholder='Recipient'
                watch={watch}
                register={register}
                name='source_recipient'
                errors={errors}
                size='sm'
                className={styles.input}
                disabled={editing || !allowlistItem ? false : true}
            />
            <div
                data-tooltip-id='priority'
                data-tooltip-content='Priority addresses are pinned to the top of the LISA view.'
                data-tooltip-place='top'
            >
                <div
                    className={classNames(
                        styles.checkboxInput,
                        !editing && allowlistItem && styles.disabled,
                        priority && styles.selected
                    )}
                    onClick={() => setPriority(!priority)}
                >
                    Priority
                    {priority ? <FaSquareCheck /> : <FaRegSquare />}
                </div>
                <ReactTooltip id='priority' />
            </div>

            {allowlistItem ? (
                <>
                    <Button
                        uitype='cta'
                        type='button'
                        size='md'
                        color='primary-dark'
                        onClick={() => {
                            clearErrors();
                            // Imperitively call the onSubmit function
                            // with the current form data.
                            handleSubmit(onSubmit)();
                        }}
                    >
                        <FaCheck />
                    </Button>
                    <Button
                        uitype='ghost'
                        size='sm'
                        type='button'
                        onClick={() => setEditing(false)}
                    >
                        <FaX />
                    </Button>
                    <div className={styles.divider} />
                    {deleting ? (
                        <>
                            <Button
                                uitype='cta'
                                type='button'
                                size='md'
                                color='red'
                                onClick={deleteItem}
                            >
                                <FaCheck />
                            </Button>
                            <Button
                                uitype='ghost'
                                size='sm'
                                type='button'
                                onClick={() => setDeleting(false)}
                            >
                                <FaX />
                            </Button>
                        </>
                    ) : (
                        <Button
                            uitype='ghost'
                            color='red'
                            size='sm'
                            type='button'
                            onClick={() => setDeleting(true)}
                        >
                            <FaTrashCan />
                        </Button>
                    )}
                </>
            ) : (
                <Button
                    uitype='cta'
                    color='primary-dark'
                    type='button'
                    size='sm'
                    onClick={() => {
                        clearErrors();
                        // Imperitively call the onSubmit function
                        // with the current form data.
                        handleSubmit(onSubmit)();
                    }}
                >
                    <FaPlus /> Save
                </Button>
            )}
        </div>
    );
};

export { AllowlistItem };
